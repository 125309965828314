@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
html {
  overflow-x: hidden;
}


.topNav {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background-color: #fff;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  padding: 0 5%;
  -webkit-filter: drop-shadow(0px 2px 12px rgba(61, 77, 81, 0.15));
  filter: drop-shadow(0px 2px 12px rgba(61, 77, 81, 0.15));
}

.topNav .chakra-breadcrumb__list {
  display: flex;
  align-items: center;
}

.topNav .chakra-breadcrumb__list-item {
  padding: 0 20px;
}

.topNav .chakra-breadcrumb__list-item {
  font-size: 16px;
}

.wrapper {
  padding: 0 5%;
}

.wrapper .css-13k6qti {
  margin: 0;
}

.fm-ip-flds {
  background: #f7f9fc !important;
  border: 2px solid #94a3a8 !important;
  box-sizing: border-box;
  box-shadow: inset 0 4px 4px rgba(124, 124, 236, 0.1);
  border-radius: 4px !important;
}

/* Add Map Modal */

.btn-ad-mp {
  font-family: Ubuntu, serif;
  font-style: normal;
  font-weight: 500 !important;
  width: 245px !important;
  height: 64px !important;
  font-size: 16px !important;
  line-height: 16px !important;
  padding: 24px;
  background: rgb(14, 94, 129) !important;
  box-shadow: rgba(14, 94, 129, 0.2) 0 4px 8px;
  border-radius: 32px !important;
  color: rgb(255, 255, 255);
}

.md-hdr-fnt {
  font-family: Ubuntu, serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #3d4d51;
}

.md-ip-lbl {
  font-family: Roboto, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #3d4d51;
}

.btn-cnl {
  background-color: transparent !important;
  font-family: Roboto, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: center;
  margin-right: 15px;
  border-radius: 8px;
}

.btn-fnt {
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center;
  letter-spacing: 0.02em;
  color: #0e5e81;
}

.btn-crt {
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff !important;
}